import styled from "styled-components";

type InnerContainerProps = {
  backgroundColor?: string;
  padding?: string;
  noHorizontalPadding?: boolean;
};

const InnerContainer = styled.div<InnerContainerProps>`
  margin: 0px auto;
  max-width: 1100px;
  display: inherit;
  align-items: inherit;
  flex-direction: inherit;
  justify-content: inherit;

  ${(props: { backgroundColor?: string; padding?: string }) => {
    if (props.backgroundColor) {
      return `background-color: ${props.backgroundColor};`;
    }
  }}
  ${(props: { backgroundColor?: string; padding?: string }) => {
    if (props.padding) {
      return `padding: ${props.padding};`;
    } else {
      return "padding: 0px 40px;";
    }
  }}
  @media only screen and (max-width: 850px) {
    padding: ${(props) => (props.noHorizontalPadding ? "0px 0px" : "0px 20px")};
  }
`;

export default InnerContainer;
