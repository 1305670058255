import { AiFillLock } from "react-icons/ai";
import { Button, Input } from "reactstrap";
import styled from "styled-components";

export const LoginWrap = styled.div`
  background-color: whitesmoke;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  padding-bottom: 100px;
`;

export const LoginBox = styled.div`
  background-color: white;
  width: clamp(200px, 100%, 500px);
  border-radius: 20px;
  margin: 0px auto;
  margin-top: 50px;
  position: relative;
  h1 {
    font-weight: 400;
  }

  @media only screen and (max-width: 500px) {
    padding: 40px 25px;
  }

  @media only screen and (min-width: 501px) {
    padding: 80px;
  }
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
`;

export const CustomInput = styled(Input)`
  height: 45px;
  margin-bottom: 15px;
`;

export const PrimaryButton = styled(Button)`
  border-radius: 30px;
  @media only screen and (min-width: 601px) {
    padding: 10px 40px;
  }
  @media only screen and (max-width: 600px) {
    padding: 10px 20px;
  }
  background-color: var(--primary);
  border: none;
  &:hover {
    background-color: #2469ff;
  }
`;

export const LoginButton = styled(PrimaryButton)`
  background-color: var(--richblue);
  color: white;
  position: relative;
  width: 100%;
  border-radius: 5px;
  font-weight: bold;
  margin-top: 20px;
`;

export const LockIcon = styled(AiFillLock)`
  position: absolute;
  left: 15px;
  top: 12.5px;
  color: var(--vibrantblue);
`;

export const AuthenticatingBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  border-radius: 20px;
`;

export const CenterDiv = styled.div`
  text-align: center;
`;
