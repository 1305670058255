import React, { useEffect, useState } from "react";

import { navigate } from "gatsby";
import { Form, FormGroup } from "reactstrap";
import { StringParam, useQueryParam } from "use-query-params";

import InnerContainer from "~/components/Containers/InnerContainer";
import HorizontalLoader from "~/components/Loaders/HorizontalLoader";
import {
  AuthenticatingBox,
  Avatar,
  CenterDiv,
  CustomInput,
  LockIcon,
  LoginBox,
  LoginButton,
  LoginWrap,
} from "~/styles/Layout/Index";
import authenticationService from "~/utils/api/v1/authenticationService";
import Auth from "~/utils/auth/auth";
import { cleanUrl } from "~/utils/validations/urls";

const avatarLogin = require(`../assets/branding/isotipo.png`).default;

const auth = new Auth();

const AuthenticatingOverlay = () => {
  return (
    <AuthenticatingBox>
      <HorizontalLoader />
    </AuthenticatingBox>
  );
};

const Login = () => {
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [authenticating, setAuthenticating] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [queryError, setQueryError] = useQueryParam("error", StringParam);
  const [redirect, setRedirect] = useQueryParam("redirect", StringParam);

  useEffect(() => {
    auth.isLoggedIn() && navigate("/dashboard/");
    scanForErrors();
  }, []);

  const scanForErrors = (): void => {
    switch (queryError) {
      case "NotLoggedIn":
        setError("Debes iniciar sesión");
        break;
    }
  };

  const enableToLogin = () => {
    return [(loginData["email"] || "").length > 0, (loginData["password"] || "").length > 0].every(Boolean);
  };

  const handleChange = (e: any): void => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  };

  const requestLogin = async () => {
    setError("");
    setAuthenticating(true);
    try {
      const authenticated = await authenticationService.login({
        ...loginData,
      });
      if (authenticated) {
        const userRoles = authenticated["https://api.saludando.cl/roles"];
        /* check if user is superadmin */
        if (!userRoles.includes("superadmin")) {
          setError("Este usuario no tiene permisos suficientes para esto");
          return;
        }

        if (!redirect) {
          navigate("/dashboard/");
        } else {
          navigate(cleanUrl(redirect));
        }

        setAuthenticating(false);
      }
    } catch (e: any) {
      setError("Uh oh, usuario o contraseña incorrectos!");
    } finally {
      setAuthenticating(false);
    }
  };

  return (
    <LoginWrap>
      <InnerContainer
        max-width="1200px"
        noHorizontalPadding={true}
      >
        <LoginBox>
          {authenticating && <AuthenticatingOverlay />}
          <CenterDiv>
            <Avatar
              src={avatarLogin}
              alt="examedi-isotipo"
            />
            <h1 style={{ marginTop: 20 }}>Ingresa a tu cuenta</h1>
            <div style={{ marginTop: 30 }}>
              <p
                style={{
                  color: "var(--red)",
                  fontSize: 14,
                  lineHeight: "14px",
                  marginBottom: 10,
                }}
              >
                {error}
              </p>
            </div>
          </CenterDiv>

          <Form
            autoComplete="new-password"
            onSubmit={(e) => {
              e.preventDefault();
              try {
                requestLogin();
              } catch (e) {
                console.log(e);
              }
            }}
          >
            <FormGroup>
              <CustomInput
                type="email"
                autoComplete="new-password"
                placeholder="Email"
                value={loginData["email"]}
                name="email"
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <CustomInput
                type="password"
                autoComplete="new-password"
                placeholder="Password"
                name="password"
                value={loginData["password"]}
                onChange={handleChange}
              />
            </FormGroup>
            <LoginButton
              type="submit"
              disabled={!enableToLogin()}
            >
              <LockIcon />
              <CenterDiv>Login</CenterDiv>
            </LoginButton>
          </Form>
        </LoginBox>
      </InnerContainer>
    </LoginWrap>
  );
};

export default Login;
