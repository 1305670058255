import React from "react";

import styled from "styled-components";

import horizontalLoader from "~/assets/animations/horizontal_loading.gif";

const HorizontalLoaderIcon = styled.img`
  height: 200px;
  width: 200px;
  object-fit: contain;
`;

const HorizontalLoader = () => {
  return <HorizontalLoaderIcon src={horizontalLoader} />;
};

export default HorizontalLoader;
