export const cleanUrl = (data: string): string => {
  try {
    const newUrl = new URL(data);
    if (newUrl.protocol === "http:" || newUrl.protocol === "https:") {
      return newUrl.href.replace(newUrl.origin, "");
    }
  } catch (err) {
    return data;
  }
};

export default cleanUrl;
